import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import { connect } from "react-redux";

function AuthLoaded({ auth, children }) {
  if (auth.isLoaded) return children;

  return (
    <Container
      style={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <CircularProgress color="secondary" />
      <Typography
        style={{ marginTop: "1rem" }}
        variant="subtitle1"
        color="textSecondary"
      >
        Loading
      </Typography>
    </Container>
  );
}
const mapStateToProps = (state) => {
  return { auth: state.firebase.auth };
};

export default connect(mapStateToProps, null)(AuthLoaded);
