const initialState = { inProgress: false, error: null };

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOGIN_REQUEST":
      return { inProgress: true, error: null };
    case "LOGIN_SUCCESS":
      return { inProgress: false, error: null };
    case "LOGIN_ERROR":
      return { inProgress: false, error: action.payload };
    case "LOGOUT_REQUEST":
      return { inProgress: true, error: null };
    case "LOGOUT_SUCCESS":
      return { inProgress: false, error: null };
    case "LOGOUT_ERROR":
      return { inProgress: false, error: action.payload };
    case "SIGNUP_REQUEST":
      return { inProgress: true, error: null };
    case "SIGNUP_SUCCESS":
      return { inProgress: false, error: null };
    case "SIGNUP_ERROR":
      return { inProgress: false, error: action.payload };
    default:
      return state;
  }
};

export default authReducer;
