const initialState = {
  inProgress: false,
  error: null,
  success: false,
};

const notesReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CREATE_NOTE_REQUEST":
      return { inProgress: true, error: null, success: false };
    case "CREATE_NOTE_SUCCESS":
      return { inProgress: false, error: null, success: true };
    case "CREATE_NOTE_ERROR":
      return { inProgress: false, error: action.payload, success: false };
    case "DELETE_NOTE_REQUEST":
      return { inProgress: true, error: null, success: false };
    case "DELETE_NOTE_SUCCESS":
      return { inProgress: false, error: null, success: true };
    case "DELETE_NOTE_ERROR":
      return { inProgress: false, error: action.payload, success: false };
    case "NOTE_RESET":
      return { inProgress: false, error: null, success: false };
    default:
      return state;
  }
};

export default notesReducer;
