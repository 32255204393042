import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Notes from "./pages/Notes";
import Create from "./pages/Create";
import About from "./pages/About";
import { Container, createMuiTheme, ThemeProvider } from "@material-ui/core";
import { purple, teal } from "@material-ui/core/colors";
import Layout from "./components/Layout";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

import React from "react";
import AuthLoaded from "./components/AuthLoaded";
const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#fefefe",
    },
    secondary: {
      main: "#e91e63",
    },
  },
  typography: {
    fontFamily: "Quicksand",
    fontWeightLight: 400,
    fontWeightRegular: 500,
    fontWeightMedium: 600,
    fontWeightBold: 700,
  },
});

function App({ auth }) {
  return (
    <ThemeProvider theme={theme}>
      <AuthLoaded>
        <Router>
          <Layout>
            <Switch>
              <Route
                exact
                path="/"
                render={() => {
                  if (auth.uid) {
                    return <Notes />;
                  } else {
                    return <Redirect to="/login" />;
                  }
                }}
              ></Route>
              <Route
                path="/create"
                render={() => {
                  if (auth.uid) {
                    return <Create />;
                  } else {
                    return <Redirect to="/login" />;
                  }
                }}
              ></Route>
              <Route path="/login">
                <Login />
              </Route>
              <Route path="/signup">
                <Signup />
              </Route>
              <Route path="/about">
                <About />
              </Route>
            </Switch>
          </Layout>
        </Router>
      </AuthLoaded>
    </ThemeProvider>
  );
}
const mapStateToProps = (state) => {
  return { auth: state.firebase.auth };
};
export default connect(mapStateToProps)(App);
