import React, { useState } from "react";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => {
  return {
    anchorOriginTopRight: {
      [theme.breakpoints.down("md")]: {
        top: "85vh",
        justifyContent: "center",
      },
    },
  };
});
function Message({ severity, message, hideMessage }) {
  const [open, setOpen] = useState(true);
  const classes = useStyles();
  const handleClose = () => {
    setOpen(false);
    hideMessage();
  };
  return (
    <Snackbar
      className={classes.anchorOriginTopRight}
      open={open}
      onClose={handleClose}
      autoHideDuration={3000}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
    >
      <MuiAlert severity={severity} elevation={6} variant="filled">
        {message}
      </MuiAlert>
    </Snackbar>
  );
}
const mapDispatchToProps = (dispatch) => {
  return {
    hideMessage: () => {
      dispatch({ type: "RESET_MESSAGE" });
    },
  };
};
export default connect(null, mapDispatchToProps)(Message);
