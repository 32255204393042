const initialState = { severity: null, message: null };

const messageReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SHOW_MESSAGE":
      return { ...action.payload };
    case "RESET_MESSAGE":
      return { severity: null, message: null };
    default:
      return state;
  }
};

export default messageReducer;
