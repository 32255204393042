import { showMessage, resetMessage } from "./messageActions";

import { actionTypes } from "redux-firestore";

export const login = (credentials) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    dispatch({ type: "LOGIN_REQUEST" });
    dispatch(showMessage("info", "Attempting to login.."));
    const firebase = getFirebase();

    firebase
      .auth()
      .signInWithEmailAndPassword(credentials.email, credentials.password)
      .then(() => {
        dispatch({ type: "LOGIN_SUCCESS" });
        dispatch(showMessage("success", "Logged in Successfully"));
      })
      .catch((err) => {
        dispatch({ type: "LOGIN_ERROR", payload: err.message });
        dispatch(showMessage("error", err.message));
      });
  };
};

export const logout = () => {
  return (dispatch, getState, { getFirebase }) => {
    dispatch({ type: "LOGOUT_REQUEST" });
    const firebase = getFirebase();
    firebase
      .auth()
      .signOut()
      .then(() => {
        dispatch({ type: "LOGOUT_SUCCESS" });
        dispatch({ type: actionTypes.CLEAR_DATA });
        dispatch(showMessage("success", "Logged out Successfully"));
      })
      .catch((err) => {
        dispatch({ type: "LOGOUT_ERROR", payload: err.message });
        dispatch(showMessage("error", err.message));
      });
  };
};

export const signup = (credentials) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    dispatch({ type: "SIGNUP_REQUEST" });
    dispatch(showMessage("info", "Attempting to create a new user"));
    const firebase = getFirebase();
    const firestore = getFirestore();
    const { email, password, firstName, lastName } = credentials;
    firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then(async (resp) => {
        await resp.user.updateProfile({
          displayName: `${firstName} ${lastName}`,
        });
        const uid = resp.user.uid;
        return uid;
      })
      .then((uid) => {
        return firestore
          .collection("users")
          .doc(uid)
          .set({
            uid: uid,
            firstName,
            lastName,
            email,
            initials: `${firstName[0]}${lastName[0]}`,
          });
      })
      .then(() => {
        dispatch({ type: "SIGNUP_SUCCESS" });
        dispatch(showMessage("success", "Signed up successfully"));
      })
      .catch((err) => {
        dispatch({ type: "SIGNUP_ERROR", payload: err.message });
        dispatch(showMessage("error", err.message));
      });
  };
};
