export const showMessage = (severity, message) => {
  return {
    type: "SHOW_MESSAGE",
    payload: { severity, message },
  };
};

export const resetMessage = (severity, message) => {
  return {
    type: "RESET_MESSAGE",
  };
};
