import React, { useState, useEffect } from "react";
import {
  Container,
  TextField,
  makeStyles,
  Button,
  CircularProgress,
  Typography,
} from "@material-ui/core";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import { connect } from "react-redux";
import { login } from "../redux/actions/authActions";
import { Redirect } from "react-router-dom";
const useStyles = makeStyles({
  textfield: {
    marginBottom: 20,
  },
  title: {
    marginBottom: 20,
  },
});
function Login({ auth, loginState, login }) {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleLogin = (e) => {
    e.preventDefault();
    // console.log(email, password);
    login({ email, password });
  };

  useEffect(() => {
    return () => {
      setEmail("");
      setPassword("");
    };
  }, []);

  if (auth.uid) return <Redirect to="/" />;

  return (
    <Container>
      <Typography className={classes.title} variant="h4" color="textSecondary">
        Login
      </Typography>
      <form noValidate autoComplete="off" onSubmit={handleLogin}>
        <TextField
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className={classes.textfield}
          variant="outlined"
          fullWidth
          color="secondary"
          label="Email"
          required
        />
        <TextField
          value={password}
          type="password"
          onChange={(e) => setPassword(e.target.value)}
          className={classes.textfield}
          variant="outlined"
          fullWidth
          color="secondary"
          label="Password"
          required
        />
        <Button
          type="submit"
          startIcon={
            loginState.inProgress ? (
              <CircularProgress size={20} />
            ) : (
              <VpnKeyIcon />
            )
          }
          color="secondary"
          variant="contained"
        >
          Login
        </Button>
      </form>
    </Container>
  );
}
const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    loginState: state.auth,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    login: (creds) => {
      dispatch(login(creds));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Login);
