import React from "react";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
function About() {
  const content = "";

  return (
    <>
      <Typography color="textSecondary" variant="subtitle1">
        Welcome to Noteify. This app is built using React, Redux and Firebase
        and lots of 💖 <br /> This is a one stop and a single source of truth to
        manage all your notes.
      </Typography>
      <br />
      <Typography>
        <Link color="secondary" href="https://pratikwadekar.me">
          Checkout my portfolio and other cool projects
        </Link>
      </Typography>
    </>
  );
}

export default About;
