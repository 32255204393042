import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { Container, Typography } from "@material-ui/core";
import NoteCard from "../components/NoteCard";
import Masonry from "react-masonry-css";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import { deleteNote } from "../redux/actions/notesActions";

function Notes({ notes, deleteNote }) {
  const handleDelete = (id) => {
    deleteNote(id);
  };

  const breakPoints = {
    default: 3,
    768: 1,
  };

  if (notes && notes.length > 0) {
    return (
      <Container>
        <Masonry
          breakpointCols={breakPoints}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column"
        >
          {notes &&
            notes.map((note) => (
              <NoteCard key={note.id} note={note} handleDelete={handleDelete} />
            ))}
        </Masonry>
      </Container>
    );
  } else {
    return (
      <Typography variant="h6" color="textSecondary">
        No Notes Created
      </Typography>
    );
  }
}

const mapStateToProps = (state) => {
  return { auth: state.firebase.auth, notes: state.firestore.ordered.notes };
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteNote: (id) => {
      dispatch(deleteNote(id));
    },
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((props) => {
    return [
      {
        collection: "notes",
        where: ["uid", "==", props.auth.uid],
      },
    ];
  })
)(Notes);
