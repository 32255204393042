import { combineReducers } from "redux";
import { firestoreReducer } from "redux-firestore";
import { firebaseReducer } from "react-redux-firebase";
import authReducer from "./authReducer";
import notesReducer from "./notesReducer";
import messageReducer from "./messageReducer";
const rootReducer = combineReducers({
  auth: authReducer,
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  notes: notesReducer,
  message: messageReducer,
});

export default rootReducer;
