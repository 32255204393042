import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./redux/reducers";
import { getFirestore, createFirestoreInstance } from "redux-firestore";
import { getFirebase, ReactReduxFirebaseProvider } from "react-redux-firebase";
import myFirebaseApp from "./firebase";
import { Provider } from "react-redux";

const env = process.env.NODE_ENV;

const store = createStore(
  rootReducer,
  env !== "production"
    ? compose(
        applyMiddleware(thunk.withExtraArgument({ getFirebase, getFirestore })),
        window.__REDUX_DEVTOOLS_EXTENSION__ &&
          window.__REDUX_DEVTOOLS_EXTENSION__()
      )
    : applyMiddleware(thunk.withExtraArgument({ getFirebase, getFirestore }))
);

const rrfProps = {
  firebase: myFirebaseApp,
  dispatch: store.dispatch,
  config: {
    useFirestoreForProfile: true,
    userProfile: "users",
    attachAuthIsReady: true,
  },
  createFirestoreInstance,
};

console.log(store);

ReactDOM.render(
  <Provider store={store}>
    <ReactReduxFirebaseProvider {...rrfProps}>
      <App />
    </ReactReduxFirebaseProvider>
  </Provider>,
  document.getElementById("root")
);
