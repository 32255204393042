import React, { useState, useEffect } from "react";
import {
  Container,
  TextField,
  makeStyles,
  Button,
  Typography,
} from "@material-ui/core";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";
import { signup } from "../redux/actions/authActions";
import { Redirect } from "react-router-dom";
import { showMessage } from "../redux/actions/messageActions";

const useStyles = makeStyles({
  textfield: {
    marginBottom: 20,
  },
  title: {
    marginBottom: 20,
  },
});

function Signup({ auth, signupState, signup, showMessage }) {
  const classes = useStyles();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleSignup = (e) => {
    e.preventDefault();
    // console.log(firstName, lastName, email, password);
    if (firstName.trim() === "") {
      showMessage("error", "First Name is a required field");
      return;
    }

    if (lastName.trim() === "") {
      showMessage("error", "Last Name is a required field");
      return;
    }

    if (email.trim() === "") {
      showMessage("error", "Email is a required field");
      return;
    }

    if (password.trim() === "") {
      showMessage("error", "Password is a required field");
      return;
    }

    signup({ firstName, lastName, email, password });
  };

  useEffect(() => {
    return () => {
      setEmail("");
      setPassword("");
      setFirstName("");
      setLastName("");
    };
  }, []);

  if (auth.uid) return <Redirect to="/" />;

  return (
    <Container>
      <Typography className={classes.title} variant="h4" color="textSecondary">
        Signup
      </Typography>
      <form noValidate autoComplete="off" onSubmit={handleSignup}>
        <TextField
          onChange={(e) => setFirstName(e.target.value)}
          className={classes.textfield}
          variant="outlined"
          fullWidth
          color="secondary"
          label="First Name"
          required
        />
        <TextField
          onChange={(e) => setLastName(e.target.value)}
          className={classes.textfield}
          variant="outlined"
          fullWidth
          color="secondary"
          label="Last Name"
          required
        />
        <TextField
          onChange={(e) => setEmail(e.target.value)}
          className={classes.textfield}
          variant="outlined"
          fullWidth
          color="secondary"
          label="Email"
          required
        />
        <TextField
          type="password"
          onChange={(e) => setPassword(e.target.value)}
          className={classes.textfield}
          variant="outlined"
          fullWidth
          color="secondary"
          label="Password"
          required
        />
        <Button
          type="submit"
          startIcon={
            signupState.inProgress ? (
              <CircularProgress size={20} />
            ) : (
              <PersonAddIcon />
            )
          }
          color="secondary"
          variant="contained"
        >
          Signup
        </Button>
      </form>
    </Container>
  );
}
const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    signupState: state.auth,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    signup: (creds) => {
      dispatch(signup(creds));
    },
    showMessage: (severity, message) => {
      dispatch(showMessage(severity, message));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Signup);
