import { showMessage } from "./messageActions";

export const createNote = (note) => {
  return (dispatch, getState, { getFirestore }) => {
    dispatch({ type: "CREATE_NOTE_REQUEST" });
    dispatch(showMessage("info", "Adding note"));
    const firestore = getFirestore();
    firestore
      .collection("notes")
      .add({
        ...note,
        createdAt: new Date(),
        uid: getState().firebase.auth.uid,
      })
      .then((doc) => {
        return doc.update({ id: doc.id });
      })
      .then(() => {
        dispatch({ type: "CREATE_NOTE_SUCCESS" });
        dispatch(showMessage("success", "Added Note Successfully"));
      })
      .catch((err) => {
        dispatch({ type: "CREATE_NOTE_ERROR", payload: err.message });
        dispatch(showMessage("error", err.message));
      });
  };
};

export const deleteNote = (noteId) => {
  return (dispatch, getState, { getFirestore }) => {
    dispatch({ type: "DELETE_NOTE_REQUEST" });
    dispatch(showMessage("info", "Deleting.."));
    const firestore = getFirestore();
    firestore
      .collection("notes")
      .doc(noteId)
      .delete()
      .then(() => {
        dispatch({ type: "DELETE_NOTE_SUCCESS" });
        dispatch(showMessage("success", "Deleted Note Successfully"));
        dispatch({ type: "NOTE_RESET" });
      })
      .catch((err) => {
        dispatch({ type: "DELETE_NOTE_ERROR", payload: err.message });
        dispatch(showMessage("error", err.message));
        dispatch({ type: "NOTE_RESET" });
      });
  };
};
