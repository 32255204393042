import React, { useRef } from "react";
import {
  Avatar,
  Button,
  Divider,
  ListItemAvatar,
  makeStyles,
} from "@material-ui/core";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import {
  Drawer,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  AppBar,
  Toolbar,
  Hidden,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import InfoIcon from "@material-ui/icons/Info";
import {
  AddCircleOutlined,
  AddCircleOutlineOutlined,
  SubjectOutlined,
  PersonAdd,
  VpnKey,
} from "@material-ui/icons";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import { format } from "date-fns";
import { connect } from "react-redux";
import { logout } from "../redux/actions/authActions";
import Message from "../components/Message";
const drawerWidth = 240;
const useStyles = makeStyles((theme) => {
  return {
    page: {
      background: "#f9f9f9",
      width: "100%",
      padding: theme.spacing(3),
      minHeight: "100vh",
    },
    drawer: {
      width: drawerWidth,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    root: {
      display: "flex",
    },
    active: {
      background: "#f4f4f4",
    },
    title: {
      padding: theme.spacing(2),
    },
    appBar: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
    toolbar: theme.mixins.toolbar,
    date: {
      flexGrow: 1,
    },
    avatar: {
      marginLeft: theme.spacing(2),
    },
  };
});

const Layout = ({ children, auth, logoutState, profile, logout, message }) => {
  // console.log(profile);
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [dialogOpen, setDialogOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const signedInLinks = [
    {
      text: "My Notes",
      icon: <SubjectOutlined color="secondary" />,
      path: "/",
    },
    {
      text: "Create Notes",
      icon: <AddCircleOutlineOutlined color="secondary" />,
      path: "/create",
    },
  ];

  const signedOutLinks = [
    {
      text: "Login",
      icon: <VpnKey color="secondary" />,
      path: "/login",
    },
    {
      text: "Signup",
      icon: <PersonAdd color="secondary" />,
      path: "/signup",
    },
    {
      text: "About",
      icon: <InfoIcon color="secondary" />,
      path: "/about",
    },
  ];

  const drawer = (
    <>
      <Typography className={classes.title} variant="h5">
        Noteify
      </Typography>

      <List>
        {auth.uid && (
          <Hidden smUp>
            <ListItem>
              <ListItemAvatar>
                <Avatar src={auth.profileUrl} />
              </ListItemAvatar>
              <ListItemText
                primary={`${profile.firstName} ${profile.lastName}`}
              ></ListItemText>
            </ListItem>
            <Divider />
          </Hidden>
        )}
        {auth.uid &&
          signedInLinks.map((item) => (
            <ListItem
              className={location.pathname === item.path ? classes.active : ""}
              button
              onClick={(theme) => {
                history.push(item.path);
              }}
              key={item.text}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItem>
          ))}

        {!auth.uid &&
          signedOutLinks.map((item) => (
            <ListItem
              className={location.pathname === item.path ? classes.active : ""}
              button
              onClick={() => {
                history.push(item.path);
              }}
              key={item.text}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItem>
          ))}

        {auth.uid && (
          <ListItem
            button
            onClick={() => {
              setDialogOpen(true);
            }}
          >
            <ListItemIcon>
              <ExitToAppIcon color="secondary" />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItem>
        )}
      </List>
    </>
  );

  const toolbar = (
    <Toolbar>
      <Hidden mdUp>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          className={classes.menuButton}
        >
          <MenuIcon />
        </IconButton>
      </Hidden>

      <Typography className={classes.date}>
        {auth.uid
          ? `Today is ${format(new Date(), "do MMMM yyyy")}`
          : "Welcome to Noteify"}
      </Typography>

      {profile.uid && (
        <Hidden smDown>
          <Typography>{`${profile.firstName} ${profile.lastName}`}</Typography>
          <Avatar src={auth.profileUrl} className={classes.avatar} />
        </Hidden>
      )}
    </Toolbar>
  );

  return (
    <div className={classes.root}>
      {message.message && <Message {...message} />}
      {/* appbar */}
      <Hidden xsDown>
        <AppBar elevation={0} className={classes.appBar}>
          {toolbar}
        </AppBar>
      </Hidden>
      <Hidden mdUp>
        <AppBar elevation={0}>{toolbar}</AppBar>
      </Hidden>
      {/* sidedrawer */}

      <Hidden smUp implementation="css">
        <div onClick={handleDrawerToggle}>
          <Drawer
            open={mobileOpen}
            onClose={handleDrawerToggle}
            className={classes.drawer}
            variant="temporary"
            anchor="left"
            classes={{ paper: classes.drawerPaper }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </div>
      </Hidden>

      <Hidden smDown implementation="css">
        <Drawer
          className={classes.drawer}
          variant="permanent"
          anchor="left"
          open
          classes={{ paper: classes.drawerPaper }}
        >
          {drawer}
        </Drawer>
      </Hidden>

      <Dialog open={dialogOpen}>
        <DialogTitle>Are you sure you want to logout?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Tired of adding notes and want to take a break
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              logout();
              setDialogOpen(false);
            }}
          >
            Yes
          </Button>
          <Button
            onClick={() => {
              setDialogOpen(false);
            }}
          >
            No
          </Button>
        </DialogActions>
      </Dialog>

      <div className={classes.page}>
        <div className={classes.toolbar}></div>
        {children}
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    logoutState: state.auth,
    profile: state.firebase.profile,
    message: state.message,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => {
      dispatch(logout());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Layout);
