import firebase from "firebase";

const firebaseConfig = {
  apiKey: "AIzaSyCn3obQX1QtSJjgAQdyY9LyGiXkPICTdfk",
  authDomain: "noteify-react-app.firebaseapp.com",
  projectId: "noteify-react-app",
  storageBucket: "noteify-react-app.appspot.com",
  messagingSenderId: "632018663651",
  appId: "1:632018663651:web:2ecbec852952a864c52dd6",
};

export default firebase.initializeApp(firebaseConfig);
